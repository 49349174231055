<Html>
  <style>
    .promo-banner {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #06c;
      padding: 20px 15px 25px;
      color: #fff;
      font-family: sans-serif;
      text-align: center;
      text-decoration: none;
    }

    .promo-banner h2,
    .promo-banner h3 {
      margin: 5px;
    }

    .promo-banner h2 {
      font-size: 38px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: underline;
    }

    .promo-banner h3 {
      font-size: 15px;
    }

    .promo-banner > span {
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
      opacity: 0.7;
    }

    .promo-banner h3 span {
      font-size: 21px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: underline;
    }
  </style>
  <!-- <a href="#springSpecial" class="promo-banner">
    <h2>Spring Special!</h2>
    <h3>Get in Shape NOW for Summer!</h3>
    <h3><span>Save $5</span> on our DVD* workouts- Ladies Vol I & Vol II</h3>
    <h3>Buy TODAY - Sale Expires May 16th</h3>
    <span>*DVD Purchases Only</span>
  </a> -->
  <body (change)="this.scrollToPay($event)">
    <app-header></app-header>
    <app-items id="dl"></app-items>
    <app-elements
      [subtotal]="this.subtotal"
      [subtax]="this.subtax"
      [shippingCosts]="this.shippingCosts"
      [internationalCosts]="this.internationalCosts"
      [amount]="
        this.subtotal +
        this.subtax +
        this.shippingCosts +
        this.internationalCosts
      "
      [description]="this.description"
      id="pay"
    >
    </app-elements>
    <app-vimeo></app-vimeo>
    <app-footer></app-footer>
  </body>
</Html>
