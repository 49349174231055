<section class="section vimeo">
  <div class="columns vimeo">
    <div class="column">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/34359837" width="100%" height="360"
        frameborder="0" allowfullscreen></iframe></div>

    <div class="column vimeo">
      <iframe src="https://player.vimeo.com/video/113115611" width="100%" height="360" frameborder="0"
        allow="autoplay; fullscreen" allowfullscreen></iframe></div>
  </div>
</section>