import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ElementsComponent } from './elements/elements.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctions, AngularFireFunctionsModule } from '@angular/fire/functions';

import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { ItemsComponent } from './items/items.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { VimeoComponent } from './vimeo/vimeo.component';

@NgModule({
  declarations: [
    AppComponent,
    ElementsComponent,
    ItemsComponent,
    FooterComponent,
    HeaderComponent,
    VimeoComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
