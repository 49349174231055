import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor() { }

  description: Subject<string> = new BehaviorSubject('');
  description$ = this.description.asObservable();

  shipping: Subject<number> = new BehaviorSubject(0);
  shipping$ = this.shipping.asObservable();
  method = 'standard';

  international: Subject<number> = new BehaviorSubject(0);
  international$ = this.international.asObservable();
  location = 'local'

  total: Subject<number> = new BehaviorSubject(0);
  total$ = this.total.asObservable();

  tax: Subject<number> = new BehaviorSubject(0);
  tax$ = this.tax.asObservable();

  cart = {
    'Kosher Workouts! Volume I': { quantity: 0, cost: 1999 },
    'Kosher Workouts! Volume II': { quantity: 0, cost: 2499 },
    'Kosher Workouts! Girls & Teens': { quantity: 0, cost: 1999 },
    'Kosher Workouts! USB Drive': { quantity: 0, cost: 4499 },
  };

  shippingOptions = {
    'Shipping': { quantity: 1, cost: 399 },
    'Rush Shipping': { quantity: 0, cost: 1199 }
  }

  internationalOptions = {
    'Local': { quantity: 1, cost: 0 },
    'International': { quantity: 0, cost: 500 }
  }

  add(name, quantity) {
    this.cart[name]['quantity'] = quantity;
    console.log(this.cart);
    console.log(this.shippingOptions);
    console.log(this.internationalOptions);
    this.calcSubtotal();
    this.calcShipping();
    this.calcDescription();
  }

  addShipping(name, quantity) {
    if (quantity === 'rush') {
      this.method = 'rush'
      this.shippingOptions['Shipping'].quantity = 0
      this.shippingOptions['Rush Shipping'].quantity = 1
    } else {
      this.method = 'standard'
      this.shippingOptions['Shipping'].quantity = 1
      this.shippingOptions['Rush Shipping'].quantity = 0
    }
    console.log(this.cart);
    console.log(this.shippingOptions);
    console.log(this.internationalOptions);
    this.calcSubtotal();
    this.calcShipping();
    this.calcDescription();
  }

  addInternational(name, quantity) {
    if (quantity === false) {
      this.location = 'local'
      this.internationalOptions['International'].quantity = 0
      this.internationalOptions['Local'].quantity = 1
    } else {
      this.location = 'international'
      this.internationalOptions['International'].quantity = 1
      this.internationalOptions['Local'].quantity = 0
    }
    console.log(this.cart);
    console.log(this.shippingOptions);
    console.log(this.internationalOptions);
    this.calcSubtotal();
    this.calcShipping();
    this.calcDescription();
  }

  calcSubtotal() {
    let subtotal = 0;
    let subtax = 0;
    for (let key in this.cart) {
      subtotal += parseInt(this.cart[key].quantity) * this.cart[key].cost
    }
    this.total.next(subtotal)
    subtax = subtotal * .08875
    this.tax.next(parseInt(subtax.toFixed(3)))
    console.log('subtotal / 100: ', subtotal / 100)
    console.log('tax / 100: ', subtax / 100)
  }

  calcShipping() {
    let noOfItems = 0;
    for (var key in this.cart) {
      noOfItems += parseInt(this.cart[key].quantity)
    }
    console.log('noOfItems: ', noOfItems)

    let shippingTotal = 0;
    for (var key in this.shippingOptions) {
      shippingTotal += parseInt(this.shippingOptions[key].quantity) * this.shippingOptions[key].cost
    }
    shippingTotal = shippingTotal + 4 ** (noOfItems - 1) * 100
    if (noOfItems === 0) {
      shippingTotal = 0
    }

    let shippingMax = 0
    if (this.method === 'rush') {
      shippingMax = 2199;
    } else {
      shippingMax = 1399;
    }
    shippingTotal = Math.min(shippingTotal, shippingMax);

    let internationalTotal = 0;
    for (var key in this.internationalOptions) {
      internationalTotal += parseInt(this.internationalOptions[key].quantity) * this.internationalOptions[key].cost * noOfItems
    }
    internationalTotal = Math.min(internationalTotal, 1500);

    this.shipping.next(shippingTotal)
    this.international.next(internationalTotal)
    console.log('shippingTotal: ', shippingTotal / 100)
    console.log('internationalTotal: ', internationalTotal / 100)
  }

  calcDescription() {
    let dvd1 = this.cart["Kosher Workouts! Volume I"].quantity > 0;
    let dvd2 = this.cart["Kosher Workouts! Volume II"].quantity > 0;
    let dvd3 = this.cart["Kosher Workouts! Girls & Teens"].quantity > 0;
    let usb = this.cart["Kosher Workouts! USB Drive"].quantity > 0;

    let items = []

    if (dvd1) {
      items.push({ "Kosher Workouts! Volume I": this.cart["Kosher Workouts! Volume I"].quantity })
    }
    if (dvd2) {
      items.push({ "Kosher Workouts! Volume II": this.cart["Kosher Workouts! Volume II"].quantity })
    }
    if (dvd3) {
      items.push({ "Kosher Workouts! Girls & Teens": this.cart["Kosher Workouts! Girls & Teens"].quantity })
    }
    if (usb) {
      items.push({ "Kosher Workouts! USB Drive": this.cart["Kosher Workouts! USB Drive"].quantity })
    }

    let descriptionString = `
    ${JSON.stringify(items, null, 2).replace(/[{}]/g, '').replace(/]|[[]/g, '').replace(/\"/g, "").replace(/,(?!["{}[\]])/g, "").replace(/\\n/g, '')}
    Shipping Method: ${this.method} 
    Shipping Locale: ${this.location}
    `

    console.log(descriptionString)
    this.description.next(descriptionString)
  }

  getDescription(): Observable<string> {
    return this.description$;
  }

  getSubtotal(): Observable<number> {
    return this.total$;
  }

  getSubtax(): Observable<number> {
    return this.tax$;
  }

  getShippingCosts(): Observable<number> {
    return this.shipping$;
  }

  getInternationalCosts(): Observable<number> {
    return this.international$;
  }

}
