import { Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  no1 = 0
  no2 = 0
  no3 = 0
  no4 = 'standard'
  no5 = false;
  no6 = 0

  constructor(public cart: CartService) { }

  ngOnInit() {
  }

  cartAdd(name, quantity): void {
    this.cart.add(name, quantity)
  }

  addShipping(name, quantity): void {
    this.cart.addShipping(name, quantity)
  }

  addInternational(name, quantity): void {
    this.cart.addInternational(name, quantity)
  }

}
