<section class="section">
    <div class="new-item">
        <h3 style="color:orange; font-size: 24px;">New Item!</h3>
        <p class="title">All 3 DVDs in one USB drive!</p>
        <p class="subtitle">Only $44.99</p>
        <figure class="image is-2by3">
            <img src="assets/usb.png">
        </figure>
        <div class="level">
            <p class="title" style="color: darkblue;">$44.99</p>
            <p>Buy USB</p>
            <div class="select">
                <select [(ngModel)]="no6" (change)="cartAdd('Kosher Workouts! USB Drive', no6)">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
        </div>
    </div>
</section>
<section class="section">
    <div class="columns">
        <div class="column">
            <figure class="image is-2by3">
                <img src="assets/Poster with Kosher Vol I.jpg">
            </figure>
            <nav class="level">
                <div class="level-item has-text-centered">
                    <div>
                        <p id="springSpecial" class="heading dvd">Kosher Workouts DVD Volume I</p>
                        <p class="heading"><a href="https://vimeo.com/ondemand/shapevol1" target="_blank"
                               style="color:orange; font-size: 24px;">Download
                                Available</a></p>
                        <br>
                        <p class="segment">Segments include:</p>
                        <div style="display: flex; justify-content: center;">
                            <div>
                                <ul align="left" style="list-style-type:none;">
                                    <li>&#9989; Zumba Dance</li>
                                    <li>&#9989; Kickboxing</li>
                                    <li>&#9989; Pilates</li>
                                    <li>&#9989; BootCamp</li>
                                </ul>
                            </div>
                        </div>
                        <!-- <p style="text-decoration: line-through; color: red;">$19.99</p> -->
                        <p class="title">$19.99</p>
                        <!-- <div class="spring-special">SPRING SPECIAL!</div> -->
                    </div>
                </div>
            </nav>
            <p>Buy DVD</p>
            <div class="select">
                <select [(ngModel)]="no1" (change)="cartAdd('Kosher Workouts! Volume I', no1)">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
        </div>




        <div class="column">
            <figure class="image is-2by3">
                <img src="assets/Poster with Kosher Vol II.png">
            </figure>
            <nav class="level">
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading  dvd">Kosher Workouts DVD Volume II<br>w/ <span class="bonus">Bonus</span>
                            Prenatal DVD</p>
                        <p class="heading"><a href="https://vimeo.com/ondemand/shapevol2" target="_blank"
                               style="color:orange; font-size: 24px;">Download
                                Available</a></p>
                        <br>
                        <p class="segment">Segments include:</p>
                        <div style="display: flex; justify-content: center;">
                            <div>
                                <ul align="left" style="list-style-type:none;">
                                    <li>&#9989; All in One</li>
                                    <li>&#9989; Zumba</li>
                                    <li>&#9989; Bootcamp</li>
                                    <li>&#9989; Pilates</li>
                                </ul>
                            </div>
                        </div>
                        <p class="segment">Prenatal Segments:</p>
                        <div style="display: flex; justify-content: center;">
                            <div>
                                <ul align="left" style="list-style-type:none;">
                                    <li>&#9989; Aerobics</li>
                                    <li>&#9989; Pilates</li>
                                </ul>
                            </div>
                        </div>
                        <!-- <p style="text-decoration: line-through; color: red;">$24.99</p> -->
                        <p class="title" style="color: darkblue;">$24.99</p>
                        <!-- <div class="spring-special">SPRING SPECIAL!</div> -->
                    </div>
                </div>
            </nav>
            <p>Buy DVD</p>
            <div class="select">
                <select [(ngModel)]="no2" (change)="cartAdd('Kosher Workouts! Volume II', no2)">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
        </div>




        <div class="column">
            <figure class="image is-2by3">
                <img src="assets/Poster with Kosher.jpg">
            </figure>
            <nav class="level">
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading dvd">Kosher Workouts DVD Girls/Teens<br>(ages 7+)</p>
                        <p class="heading"><a href="https://vimeo.com/ondemand/shapeforkids" target="_blank"
                               style="color:orange; font-size: 24px;">Download
                                Available</a></p>
                        <br>
                        <p class="segment">Segments include:</p>
                        <div style="display: flex; justify-content: center;">
                            <div>
                                <ul align="left" style="list-style-type:none;">
                                    <li>&#9989; Kickboxing Party</li>
                                    <li>&#9989; Funtastics Gymnastics</li>
                                    <li>&#9989; Dancersize</li>
                                </ul>
                            </div>
                        </div>
                        <p class="title">$19.99</p>
                    </div>
                </div>
            </nav>
            <p>Buy DVD</p>
            <div class="select">
                <select [(ngModel)]="no3" (change)="cartAdd('Kosher Workouts! Girls & Teens', no3)">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
        </div>
    </div>
</section>





<div class="content">
    <div class="control">
        <label class="radio">
            <input type="radio" name="shipping" value="standard" [(ngModel)]="no4"
                   (change)="addShipping('shipping', no4)">
            Standard shipping
        </label>
        <label class="radio">
            <input type="radio" name="shipping" value="rush" [(ngModel)]="no4" (change)="addShipping('shipping', no4)">
            Rush shipping
        </label>
    </div>
</div>


<!-- <div class="content">
    <div class="control">
        <label class="checkbox">
            <input type="checkbox" name="international" [checked]="no5"
                (change)="no5 = !no5; addInternational('international', no5)">
            International
        </label>
    </div>
</div> -->


<div style="display: flex; justify-content: center; padding: 0 50px;">
    <div style="margin: 0 auto; max-width: 700px;">
        <p class="title shipping-note">Available at your local Judaica stores throughout the United States.
            Including
            Shanky's in Jerusalem & London.
            Ships within 2-3 business days.</p>
    </div>
</div>