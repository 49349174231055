<div class="modal" [class.is-active]="modalOpen">
  <div class="modal-background" (click)="modalOpen=false"></div>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title">Tanya Rosen</p>
      <button class="delete" aria-label="close" (click)="modalOpen=false"></button>
    </header>

    <section class="modal-card-body">

      <p>
        Tanya holds a master’s degree from Brooklyn College, as well as many specialty certificates such as pre and post
        natal fitness and nutrition, sports nutrition, life coaching, cognitive behavioral therapy for emotional eaters
        and working with special populations such as children, senior citizens and athletes.
      </p>
      <br>

      <p>
        Tanya has extensive knowledge and experience in working with complex medical conditions and eating
        disorders. As
        a trainer and group class instructor, Tanya is certified to teach kickboxing, spin, pilates, boot camp, step and
        more. With the motto that no two classes should ever be the same, Tanya is always bringing new routines and
        moves to her popular classes. Tanya is also the creator of the first ever kosher workout DVD series called Shape
        Fitness Kosher Workout, created for women and girls bringing her best workouts to the comfort of people’s homes.
      </p>
      <br>

      <p>
        Always a writer, Tanya enjoys writing for the Jewish Press and Binah Magazine, as well as working on her future
        book. On a personal level, Tanya understands the struggle as she herself lost close to 50 pounds almost fifteen
        years ago and works hard to keep it off. With almost 15 years of experience as a personal trainer, aerobics
        instructor and nutritionist, Tanya directs the program with new ideas and her constant support and
        availability
        towards all her clients.
      </p>

      <br>
      <p>Learn more at: <a href="https://nutritionbytanya.com/" target="_blank">Nutrition by Tanya</a></p>
    </section>

    <footer class="modal-card-foot">
    </footer>

  </div>
  <button class="modal-close is-large" aria-label="close" (click)="modalOpen=false"></button>
</div>







<body>


  <div class="logo">

    <nav class="level">
      <p class="level-item has-text-centered">
        <img src="/assets/shape_logo.png" alt="Shape Fitness Logo">
      </p>
    </nav>

  </div>





  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <p class="title">
          The Best of Shape Fitness Kosher Workouts featuring <span (click)="modalOpen=true"
            style="color: purple; cursor: pointer; text-decoration: underline">Tanya Rosen!</span>
        </p>
        <p class="subtitle">
          Every workout segment is 20 minutes long & includes a warm up & cool down, so you get a complete workout
          for which ever segment you choose!
        </p>
        <div class="about-list">
          <div>
            <ul align="left" style="list-style-type:none;">
              <li>&#9989; Kosher Music</li>
              <li>&#9989; 100% Tznius</li>
              <li>&#9989; Fit for your home</li>
              <li>&#9989; Great for ALL Levels</li>
              <li>&#9989; Fun & Easy to Follow</li>
              <li>&#9989; No Equipment Necessary</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>


</body>