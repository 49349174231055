<div class="modal" [class.is-active]="modalOpen">
  <div class="modal-background" (click)="modalOpen=false"></div>
  <div class="modal-card">

    <header class="modal-card-head">
      <p class="modal-card-title">Tanya Rosen</p>
      <button class="delete" aria-label="close" (click)="modalOpen=false"></button>
    </header>

    <section class="modal-card-body">

      <p>
        Tanya holds a master’s degree from Brooklyn College, as well as many specialty certificates such as pre and post
        natal fitness and nutrition, sports nutrition, life coaching, cognitive behavioral therapy for emotional eaters
        and working with special populations such as children, senior citizens and athletes.
      </p>
      <br>

      <p>
        Tanya has extensive knowledge and experience in working with complex medical conditions and eating
        disorders. As
        a trainer and group class instructor, Tanya is certified to teach kickboxing, spin, pilates, boot camp, step and
        more. With the motto that no two classes should ever be the same, Tanya is always bringing new routines and
        moves to her popular classes. Tanya is also the creator of the first ever kosher workout DVD series called Shape
        Fitness Kosher Workout, created for women and girls bringing her best workouts to the comfort of people’s homes.
      </p>
      <br>

      <p>
        Always a writer, Tanya enjoys writing for the Jewish Press and Binah Magazine, as well as working on her future
        book. On a personal level, Tanya understands the struggle as she herself lost close to 50 pounds almost fifteen
        years ago and works hard to keep it off. With almost 15 years of experience as a personal trainer, aerobics
        instructor and nutritionist, Tanya directs the program with new ideas and her constant support and
        availability
        towards all her clients.
      </p>

      <br>
      <p>Learn more at: <a href="https://nutritionbytanya.com/" target="_blank">Nutrition by Tanya</a></p>
    </section>

    <footer class="modal-card-foot">
    </footer>

  </div>
  <button class="modal-close is-large" aria-label="close" (click)="modalOpen=false"></button>
</div>





<head>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
</head>

<body>
  <footer class="footer has-background-light">
    <div class="content has-text-centered has-text-white">

      <div class="columns">
        <div class="column">
          <a (click)="modalOpen=true" class="button is-success is-rounded">About Tanya</a>

        </div>

        <div class="column">

          <a href="mailto:shapefitness2@yahoo.com" class="button is-success is-rounded">Contact Us</a>
        </div>
      </div>
    </div>
  </footer>


  <div class="has-background-success has-text-white bottom dark">
    <div class="container is-fluid">
      <div class="column align-center">
        <div class="column has-text-centered">
          <a href="https://www.facebook.com/groups/720766254655089/"><i class="fa fa-facebook-square"></i></a>
          <a href="https://www.instagram.com/nutritionbytanya/"><i class="fa fa-instagram"></i></a>
          <a href="https://vimeo.com/shapefitness/vod_pages"><i class="fa fa-vimeo-square"></i></a>
          <a href="https://www.youtube.com/channel/UCdFNtgyRxJNukjYfYB0pyBA/videos"><i
              class="fa fa-youtube-play"></i></a>
        </div>
      </div>
    </div>
  </div>


</body>