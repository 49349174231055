import { Component, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../environments/environment';

declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss']
})
export class ElementsComponent implements AfterViewInit {

  constructor(private functions: AngularFireFunctions) { }

  @Input() amount: number;
  @Input() subtotal: number;
  @Input() subtax: number;
  @Input() shippingCosts: number;
  @Input() internationalCosts: number;
  @Input() description: string;
  @ViewChild("stripeContainer") stripeContainer: ElementRef;

  confirmation;

  api_key: string = environment.api_key

  card: any;
  stripe: any;
  token: string;
  elements: any;
  form: any;
  resetButton: any;
  errorvisible: boolean = false;
  error_message = "";

  ngAfterViewInit() {

    this.form = this.stripeContainer.nativeElement.querySelector('form');

    this.resetButton = this.stripeContainer.nativeElement.querySelector('a.reset');

    this.stripe = Stripe(this.api_key);

    this.elements = this.stripe.elements({
      // Stripe's examples are localized to specific languages, but if
      // you wish to have Elements automatically detect your user's locale,
      // use `locale: 'auto'` instead.
      locale: 'en'
    });

    /**
     * Card Element
     */
    this.card = this.elements.create("card", {
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#fff",
          color: "#fff",
          fontWeight: 400,
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          fontSize: "15px",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#BFAEF6"
          },
          ":-webkit-autofill": {
            color: "#fce883"
          }
        },
        invalid: {
          iconColor: "#FFC7EE",
          color: "#FFC7EE"
        }
      }
    });
    this.card.mount("#example5-card");

    this.card.on('change', (event) => {
      this.cardOnChange(event);
    });

    this.form.addEventListener('submit', (e) => {
      e.preventDefault(); // this needs to be here, not in onSubmit for some reason.
      this.onSubmit(e);
    });

  } // END ngAfterViewInit //

  cardOnChange(event) {
    var savedErrors = {};

    if (event.error) {
      this.errorvisible = true;
      savedErrors[0] = event.error.message;
      this.error_message = event.error.message;
    } else {
      savedErrors[0] = null;
      var nextError = Object.keys(savedErrors)
        .sort()
        .reduce((maybeFoundError, key) => {
          return maybeFoundError || savedErrors[key];
        }, null);
      if (nextError) {
        this.error_message = nextError;
      } else {
        this.errorvisible = false;
      }
    }
  }

  onSubmit(e) {
    e.preventDefault();

    this.disableInputs()

    let name = this.form.querySelector('#example5-name');
    let address1 = this.form.querySelector('#example5-address');
    let city = this.form.querySelector('#example5-city');
    let state = this.form.querySelector('#example5-state');
    let zip = this.form.querySelector('#example5-zip');
    // let country = this.form.querySelector('#example5-country');
    let country = 'USA'
    let phone = this.form.querySelector('#example5-phone');

    let email = this.form.querySelector('#example5-email');

    let shipping = {
      name: name ? name.value : undefined,
      phone: phone ? phone.value : undefined,
      tracking_number: email ? email.value : undefined,
      address: {
        line1: address1 ? address1.value : undefined,
        city: city ? city.value : undefined,
        state: state ? state.value : undefined,
        postal_code: zip ? zip.value : undefined,
        country: country
      }
    };

    let description = `
    Thank you for your business!
    ${this.description}
    ${JSON.stringify(shipping, null, 2).replace(/[{}]/g, '').replace(/]|[[]/g, '').replace(/\"/g, "").replace(/,(?!["{}[\]])/g, "").replace(/\\n/g, '')}
    Subtotal: ${(this.subtotal / 100).toFixed(2)}
    Tax: ${(this.subtax / 100).toFixed(2)}
    Shipping: ${(this.shippingCosts / 100).toFixed(2)}
    Total: ${(this.amount / 100).toFixed(2)}
    `

    let receipt_email = 'shapefitness2@yahoo.com'

    this.stripe.createToken(this.card).then(async (result) => {

      if (result.token) {
        this.stripeContainer.nativeElement.classList.add('submitting');
        const fun = this.functions.httpsCallable('stripeCreateCharge');
        this.confirmation = await fun({ source: result.token.id, amount: this.amount, description: description, shipping: shipping, receipt_email: receipt_email }).toPromise();
        this.stripeContainer.nativeElement.classList.remove('submitting');
        this.stripeContainer.nativeElement.classList.add('submitted');
      } else {
        this.enableInputs();
      }
    });
  }

  onReset(e) {
    e.preventDefault();
    this.form.reset();
    this.card.clear();
    this.errorvisible = false;
    this.enableInputs();
    this.stripeContainer.nativeElement.classList.remove('submitted');
  }

  enableInputs() {
    Array.prototype.forEach.call(
      this.form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      (input) => {
        input.removeAttribute('disabled');
      }
    );
  }

  disableInputs() {
    Array.prototype.forEach.call(
      this.form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      (input) => {
        input.setAttribute('disabled', 'true');
      }
    );
  }

}
