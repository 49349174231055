import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { CartService } from './cart.service';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('pay') pay: ElementRef;
  scrollFired = false;

  title = 'shape-stripe';

  subtotal: number;
  subtax: number;
  shippingCosts: number;
  internationalCosts: number;

  description: string;
  amount: number;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(public cart: CartService, private vps: ViewportScroller) { }

  ngOnInit() {
    this.getSubtotal();
    this.getSubtax();
    this.getShippingCosts();
    this.getInternationalCosts();
    this.getDescription();
  }

  getDescription(): void {
    this.cart.getDescription().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(description => this.description = description);
  }

  getSubtotal(): void {
    this.cart.getSubtotal().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(subtotal => this.subtotal = subtotal);
  }

  getSubtax(): void {
    this.cart.getSubtax().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(subtax => this.subtax = subtax);
  }

  getShippingCosts(): void {
    this.cart.getShippingCosts().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(shippingCosts => this.shippingCosts = shippingCosts);
  }

  getInternationalCosts(): void {
    this.cart.getInternationalCosts().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(internationalCosts => this.internationalCosts = internationalCosts);
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  scrollToPay($event): void {
    if ($event.target.value > 0 && this.scrollFired === false && $event.target.type === 'select-one') {
      this.scrollFired = true;
      this.vps.scrollToAnchor('pay');
    }
  }
}
